body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #191b23;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'DMSans';
  src: url('./fonts/DMSans-Regular.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Visby';
  src: url('./fonts/Visby/VisbyRegular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'DMSansBold';
  src: url('./fonts/DMSans-Bold.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'VisbyBold';
  src: url('./fonts/Visby/VisbyBold.otf') format('opentype');
  font-display: swap;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: #6f5ce6;
}
